(function _() {
  const hiddenKey = 'hidden';
  const talkdeskUrlKey = 'talkdesk_url';
  const defaultTalkdeskUrl = 'https://www.mytalkdesk.com/users/sign_in';
  const truthyValues = [1, '1', true, 'true'];
  const query = document.currentScript.src.split('?')[1];

  function isTrue(value) {
    return truthyValues.includes(value.toLowerCase());
  }

  function hasHiddenParam(e) {
    const qs = new URLSearchParams(e);
    return qs.has(hiddenKey) && isTrue(qs.get(hiddenKey));
  }

  function getTalkdeskUrl(e) {
    const qs = new URLSearchParams(e);
    return qs.has(talkdeskUrlKey) ? qs.get(talkdeskUrlKey) : defaultTalkdeskUrl;
  }

  const isWhiteLabel = query && hasHiddenParam(query);
  const talkdeskUrl = query ? getTalkdeskUrl(query) : defaultTalkdeskUrl;

  function TalkdeskConnect() {}

  TalkdeskConnect.prototype.attachDiv = function attachDiv() {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = '__CSS_URL__';
    document.querySelector('head').appendChild(link);

    this.div = document.createElement('div');
    this.div.id = 'td-connect';
    this.div.innerHTML = this.getContent();
    return document.querySelector('body').appendChild(this.div);
  };

  TalkdeskConnect.prototype.getContent = function getContent() {
    return `<a href=${decodeURIComponent(talkdeskUrl)} class="td-logo" alt="Talkdesk"></a><div class="app-connect"><span class="menu__divider"></span><a href="https://appconnect.talkdesk.com/" alt="Talkdesk AppConnect" rel="noopener" target="_blank"><i class="co-icon__myapps"></i><div class="td-brand__myapps"></div></a></div>`;
  };

  TalkdeskConnect.prototype.init = function init() {
    if (isWhiteLabel) {
      return;
    }
    this.attachDiv();
  };

  window.TalkdeskConnect = new TalkdeskConnect();
}());
